<template>
  <form>
    <div class="form-logo">
      <img src="/images/logo.png" alt="Logo" />
    </div>
    <div class="form-control">
      <label for="email">Correo electrónico:</label>
      <input type="email" name="email" placeholder="Ingrese su correo" />
    </div>
    <div class="form-control">
      <label for="password">Contraseña:</label>
      <div class="input-w-icon">
        <input
          type="password"
          name="password"
          ref="inputPassword"
          placeholder="Ingrese su contraseña"
        />

        <i
          @click="changeType"
          :class="[!showEyesIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"
        ></i>
      </div>
    </div>
    <div class="form-control">
      <label for="password">Contraseña:</label>
      <div class="input-w-icon">
        <input
          type="password"
          name="password"
          ref="inputPassword2"
          placeholder="Ingrese su contraseña"
        />

        <i
          @click="changeType2"
          :class="[!showEyesIcon2 ? 'fas fa-eye' : 'fas fa-eye-slash']"
        ></i>
      </div>
    </div>
    <div class="text-center mt-2">
      <button class="btn btn-primary">
        Registrarme
      </button>
    </div>
    <div class="text-center mt-1">
      <router-link class="link" :to="{ name: 'login' }"
        >Iniciar Sesión</router-link
      >
    </div>
  </form>
</template>

<script>
import useToogglePass from '@/modules/auth/composables/useTogglePass';
export default {
  setup() {
    const { changeType, inputPassword, showEyesIcon } = useToogglePass();
    const {
      changeType: changeType2,
      inputPassword: inputPassword2,
      showEyesIcon: showEyesIcon2,
    } = useToogglePass();

    return {
      changeType,
      inputPassword,
      showEyesIcon,

      changeType2,
      inputPassword2,
      showEyesIcon2,
    };
  },
};
</script>
